// Gallery.jsx
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { RowsPhotoAlbum } from "react-photo-album";
import { useUserStore } from '../store';
import "react-photo-album/rows.css";

const Gallery = ({ photos, isAdmin, publicBackUrl }) => {
  const [targetRowHeight, setTargetRowHeight] = useState(230);
  const [hoverSlug, setHoverSlug] = useState(null);
  const [isSKeyPressed, setIsSKeyPressed] = useState(false);
  const [isDKeyPressed, setIsDKeyPressed] = useState(false);
  const [photosState, setPhotosState] = useState([]);
  const [processedItems, setProcessedItems] = useState({});
  const [ratingChanged, setRatingChanged] = useState({});
  const [dimmedItems, setDimmedItems] = useState({});
  
  // refs для быстрого доступа к актуальному состоянию
  const photosRef = useRef([]);
  const hoverSlugRef = useRef(null);
  const isSKeyPressedRef = useRef(false);
  const isDKeyPressedRef = useRef(false);
  const pendingChangesRef = useRef({});
  const timeoutRef = useRef(null);
  const ratingTimeoutRef = useRef(null);
  const dimmedItemsRef = useRef({});
  
  // Получаем состояния администратора из хранилища
  const adminViewEnabled = useUserStore(state => state.adminViewEnabled);
  const selectedCollection = useUserStore(state => state.selectedCollection);
  const customCollection = useUserStore(state => state.customCollection);
  
  // Рефы, чтобы внутри колбэков иметь актуальные значения
  const selectedCollectionRef = useRef(selectedCollection);
  const customCollectionRef = useRef(customCollection);
  const adminViewEnabledRef = useRef(adminViewEnabled);
  
  // Синхронизируем эти рефы при изменениях
  useEffect(() => {
    selectedCollectionRef.current = selectedCollection;
    customCollectionRef.current = customCollection;
    adminViewEnabledRef.current = adminViewEnabled;
  }, [selectedCollection, customCollection, adminViewEnabled]);
  
  // Синхронизируем dimmedItemsRef с dimmedItems
  useEffect(() => {
    dimmedItemsRef.current = dimmedItems;
  }, [dimmedItems]);
  
  // Возвращает текущую «активную» коллекцию
  const getActiveCollection = useCallback(() => {
    return selectedCollectionRef.current === 'custom' 
      ? customCollectionRef.current 
      : selectedCollectionRef.current;
  }, []);
  
  // При обновлении props.photos
  useEffect(() => {
    if (photos && photos.length > 0) {
      photosRef.current = [...photos];
      setPhotosState(photos);
      
      // Инициализируем dimmedItems на основе is_active
      const newDimmedItems = {};
      photos.forEach(photo => {
        // Проверяем именно на false, а не на falsy значение
        if (photo.is_active === false) {
          newDimmedItems[photo.key] = { timestamp: Date.now() };
        }
      });
      
      console.log("Найдены неактивные фото:", Object.keys(newDimmedItems));
      
      // Обновляем dimmedItems только если что-то изменилось
      if (Object.keys(newDimmedItems).length > 0) {
        setDimmedItems(prevDimmed => ({
          ...prevDimmed,
          ...newDimmedItems
        }));
      }
    }
  }, [photos]);

  // Адаптивная высота ряда
  useEffect(() => {
    const updateRowHeight = () => {
      setTargetRowHeight(window.innerWidth <= 768 ? 120 : 200);
    };

    updateRowHeight();
    window.addEventListener('resize', updateRowHeight);
    return () => window.removeEventListener('resize', updateRowHeight);
  }, []);

  // Добавить/удалить фото в коллекцию
  const processImage = useCallback((slug) => {
    if (!isAdmin || !slug || !adminViewEnabledRef.current) {
      console.log("processImage: не админ или админ-режим выключен");
      return;
    }
    
    const currentCollection = getActiveCollection();
    if (!currentCollection) {
      console.error("processImage: коллекция не выбрана");
      return;
    }
    
    const photo = photosRef.current.find(p => p.key === slug);
    if (!photo) return;
    
    // Есть ли уже эта коллекция у фото?
    const collections = photo.collection 
      ? photo.collection.split(',').map(c => c.trim()) 
      : [];
    const isInCollection = collections.includes(currentCollection);
    
    const action = isInCollection ? 'remove' : 'add';
    
    // Запоминаем в pending
    pendingChangesRef.current[slug] = action;
    
    // Визуальная метка «Добавлено/Удалено»
    setProcessedItems(prev => ({
      ...prev,
      [slug]: { action, timestamp: Date.now(), collection: currentCollection }
    }));
    
    // Оптимистичное обновление стейта
    setPhotosState(prev => prev.map(p => {
      if (p.key !== slug) return p;
      const photoCollections = p.collection
        ? p.collection.split(',').map(c => c.trim())
        : [];
      
      if (action === 'add' && !photoCollections.includes(currentCollection)) {
        photoCollections.push(currentCollection);
      } else if (action === 'remove') {
        const idx = photoCollections.indexOf(currentCollection);
        if (idx !== -1) photoCollections.splice(idx, 1);
      }
      
      return {
        ...p,
        collection: photoCollections.filter(Boolean).join(', ')
      };
    }));
    
    // То же самое в ref-массиве
    photosRef.current = photosRef.current.map(p => {
      if (p.key !== slug) return p;
      const photoCollections = p.collection
        ? p.collection.split(',').map(c => c.trim())
        : [];
      
      if (action === 'add' && !photoCollections.includes(currentCollection)) {
        photoCollections.push(currentCollection);
      } else if (action === 'remove') {
        const idx = photoCollections.indexOf(currentCollection);
        if (idx !== -1) photoCollections.splice(idx, 1);
      }
      
      return {
        ...p,
        collection: photoCollections.filter(Boolean).join(', ')
      };
    });
    
    // Отложенная отправка на сервер
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(sendChangesToServer, 300);
  }, [isAdmin, getActiveCollection]);

  // Удалить/восстановить фото
  const toggleDimImage = useCallback((slug) => {
    if (!isAdmin || !slug || !adminViewEnabledRef.current) {
      console.log("toggleDimImage: не админ или админ-режим выключен");
      return;
    }
    
    const photo = photosRef.current.find(p => p.key === slug);
    if (!photo) {
      console.error("toggleDimImage: фото не найдено", slug);
      return;
    }
    
    // Определяем текущее состояние
    const isDimmed = Boolean(dimmedItemsRef.current[slug]);
    const newDimState = !isDimmed;
    const action = newDimState ? 'delete' : 'restore';
    
    console.log(`toggleDimImage: ${slug}, action=${action}`);
    
    // Оптимистичное обновление
    setDimmedItems(prev => {
      const next = { ...prev };
      if (newDimState) {
        next[slug] = { timestamp: Date.now() };
      } else {
        delete next[slug];
      }
      return next;
    });
    
    // Запрос к серверу
    (async () => {
      try {
        const response = await fetch(`${publicBackUrl}/asset_status`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ slug, action })
        });
        
        if (!response.ok) {
          throw new Error(`toggleDimImage: ошибка ${response.status}`);
        }
        
        const result = await response.json();
        if (result && result.success) {
          // Сервер подтвердил успех
          setDimmedItems(prev => {
            const updated = { ...prev };
            // Смотрим, что вернулось в поле is_active
            if (!result.is_active) {
              updated[slug] = { timestamp: Date.now() };
            } else {
              delete updated[slug];
            }
            return updated;
          });
        } else {
          throw new Error("toggleDimImage: сервер не смог выполнить операцию");
        }
      } catch (err) {
        console.error(`toggleDimImage: ошибка при action=${action}`, err);
        // Откатываем
        setDimmedItems(prev => {
          const reverted = { ...prev };
          if (isDimmed) {
            reverted[slug] = { timestamp: Date.now() };
          } else {
            delete reverted[slug];
          }
          return reverted;
        });
      }
    })();
  }, [isAdmin, publicBackUrl]);

  // Изменить рейтинг
  const changeRating = useCallback(async (slug, newRating) => {
    if (!isAdmin || !slug || !adminViewEnabledRef.current) {
      console.log("changeRating: не админ или админ-режим выключен");
      return;
    }
    
    const photo = photosRef.current.find(p => p.key === slug);
    if (!photo) return;
    
    // Оптимистичное обновление
    setPhotosState(prev => prev.map(p => {
      return p.key === slug ? { ...p, rating: newRating } : p;
    }));
    photosRef.current = photosRef.current.map(p => {
      return p.key === slug ? { ...p, rating: newRating } : p;
    });
    
    // Индикатор изменения рейтинга
    setRatingChanged(prev => ({ ...prev, [slug]: true }));
    if (ratingTimeoutRef.current) clearTimeout(ratingTimeoutRef.current);
    ratingTimeoutRef.current = setTimeout(() => {
      setRatingChanged(prev => {
        const next = { ...prev };
        delete next[slug];
        return next;
      });
    }, 2000);
    
    // Запрос к серверу
    try {
      const response = await fetch(`${publicBackUrl}/update_asset_rating`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ slug, rating: newRating })
      });
      if (!response.ok) {
        throw new Error(`changeRating: ошибка сервера ${response.status}`);
      }
      console.log(`Рейтинг обновлён: ${slug} => ${newRating}`);
    } catch (err) {
      console.error("changeRating: ошибка запроса", err);
      // Откат
      setPhotosState(prev => prev.map(p => {
        return p.key === slug ? { ...p, rating: photo.rating } : p;
      }));
      photosRef.current = photosRef.current.map(p => {
        return p.key === slug ? { ...p, rating: photo.rating } : p;
      });
    }
  }, [isAdmin, publicBackUrl]);

  // Отправка «пакетных» изменений (добавление/удаление из коллекции)
  const sendChangesToServer = useCallback(async () => {
    if (!adminViewEnabledRef.current) {
      console.log("sendChangesToServer: админ-режим выключен, очищаем pending");
      pendingChangesRef.current = {};
      return;
    }
    
    const changes = pendingChangesRef.current;
    if (!Object.keys(changes).length) return;
    
    const currentCollection = getActiveCollection();
    if (!currentCollection) {
      console.error("sendChangesToServer: коллекция не выбрана");
      return;
    }
    
    const addSlugs = [];
    const removeSlugs = [];
    
    for (const [slug, action] of Object.entries(changes)) {
      if (action === 'add') addSlugs.push(slug);
      else if (action === 'remove') removeSlugs.push(slug);
    }
    
    pendingChangesRef.current = {}; // очищаем перед запросами
    
    try {
      const requests = [];
      if (addSlugs.length > 0) {
        requests.push(
          fetch(`${publicBackUrl}/admin_collection_management`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              slugs: addSlugs,
              collection: currentCollection,
              action: "add"
            })
          })
        );
      }
      if (removeSlugs.length > 0) {
        requests.push(
          fetch(`${publicBackUrl}/admin_collection_management`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              slugs: removeSlugs,
              collection: currentCollection,
              action: "remove"
            })
          })
        );
      }
      
      if (requests.length > 0) {
        const results = await Promise.all(requests);
        for (const r of results) {
          if (!r.ok) throw new Error(`Ошибка сервера: ${r.status}`);
        }
      }
      console.log("sendChangesToServer: Изменения сохранены");
    } catch (err) {
      console.error("sendChangesToServer: ошибка", err);
    }
  }, [getActiveCollection]);

  // Когда мышь заходит на картинку
  const handleImageHover = useCallback((slug) => {
    if (!slug) return;
    
    setHoverSlug(slug);
    hoverSlugRef.current = slug;
    
    // Если D уже зажата – сразу переключаем «удалённость»
    if (isDKeyPressedRef.current && isAdmin && adminViewEnabledRef.current) {
      toggleDimImage(slug);
    }
    // Если S уже зажата – сразу добавляем/удаляем из коллекции
    if (isSKeyPressedRef.current && isAdmin && adminViewEnabledRef.current) {
      processImage(slug);
    }
  }, [isAdmin, toggleDimImage, processImage]);

  // Глобальные клавиатурные обработчики
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isAdmin || !adminViewEnabledRef.current) return;
      
      // S (включаем «режим» добавления/удаления по наведению)
      if (e.code === 'KeyS' && !isSKeyPressedRef.current) {
        setIsSKeyPressed(true);
        isSKeyPressedRef.current = true;
        document.body.style.cursor = 'crosshair';
        
        // Если уже наведены на картинку — обрабатываем её сразу
        if (hoverSlugRef.current) {
          processImage(hoverSlugRef.current);
        }
      }
      
      // D (включаем «режим» удаления/восстановления по наведению)
      if (e.code === 'KeyD' && !isDKeyPressedRef.current) {
        setIsDKeyPressed(true);
        isDKeyPressedRef.current = true;
        document.body.style.cursor = 'not-allowed';
        
        // Если уже наведены на картинку — обрабатываем её сразу
        if (hoverSlugRef.current) {
          toggleDimImage(hoverSlugRef.current);
        }
      }
      
      // Цифровые клавиши (1..9): рейтинг
      const row1Digits = ['Digit1','Digit2','Digit3','Digit4','Digit5','Digit6','Digit7','Digit8','Digit9'];
      const numpadDigits = ['Numpad1','Numpad2','Numpad3','Numpad4','Numpad5','Numpad6','Numpad7','Numpad8','Numpad9'];
      let ratingValue = null;
      
      if (row1Digits.includes(e.code)) {
        ratingValue = parseInt(e.code.replace('Digit',''));
      } else if (numpadDigits.includes(e.code)) {
        ratingValue = parseInt(e.code.replace('Numpad',''));
      }
      
      if (ratingValue && hoverSlugRef.current) {
        changeRating(hoverSlugRef.current, ratingValue);
      }
    };

    const handleKeyUp = (e) => {
      if (e.code === 'KeyS') {
        setIsSKeyPressed(false);
        isSKeyPressedRef.current = false;
        document.body.style.cursor = '';
        // Отправляем накопленные изменения
        if (adminViewEnabledRef.current) {
          sendChangesToServer();
        }
      }
      
      if (e.code === 'KeyD') {
        setIsDKeyPressed(false);
        isDKeyPressedRef.current = false;
        document.body.style.cursor = '';
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      document.body.style.cursor = '';
      // Если остались неотправленные изменения
      if (Object.keys(pendingChangesRef.current).length && adminViewEnabledRef.current) {
        sendChangesToServer();
      }
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (ratingTimeoutRef.current) clearTimeout(ratingTimeoutRef.current);
    };
  }, [isAdmin, changeRating, processImage, sendChangesToServer, toggleDimImage]);

  // Если нет фото, ничего не рендерим
  if (!photosState.length) return null;

  return (
    <RowsPhotoAlbum 
      photos={photosState}
      targetRowHeight={targetRowHeight}
      spacing={8}
      render={{
        extras: (_, { photo }) => {
          const { isFree, url, mediaType, collection, key, rating } = photo;
          
          // Проверяем, есть ли фото в текущей коллекции
          const activeCollection = getActiveCollection();
          const collections = collection ? collection.split(',').map(c => c.trim()) : [];
          const isInSelectedCollection = collections.includes(activeCollection);
          
          const isHovered = hoverSlug === key;
          const wasProcessed = processedItems[key];
          const wasRatingChanged = ratingChanged[key];
          
          // Проверяем только по dimmedItems
          const isDimmed = Boolean(dimmedItems[key]);
          
          const elements = [];
          
          // Иконка короны
          if (!isFree) {
            elements.push(
              <div 
                key="crown-icon"
                style={{
                  position: 'absolute',
                  top: 8,
                  left: 8,
                  zIndex: 10,
                  width: '16px',
                  height: '16px',
                }}
              >
                <img 
                  src="/images/crown.svg" 
                  alt="Premium" 
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            );
          }
          
          // Если фото «затемнено», показываем оверлей с "Deleted" по центру
          if (isDimmed) {
            elements.push(
              <div 
                key="dimmed-overlay"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  zIndex: 2,
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // ВАЖНО: пропускаем события сквозь оверлей
                  pointerEvents: 'none'
                }}
              >
                <div style={{
                  color: 'white',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  textShadow: '1px 1px 3px rgba(0,0,0,0.8)'
                }}>
                  Deleted
                </div>
              </div>
            );
          }
          
          // Основная ссылка (для просмотра), которая ловит hover
          elements.push(
            <a 
              key="link"
              href={url}
              style={{ 
                position: 'absolute',
                top: 0, left: 0,
                width: '100%', height: '100%',
                zIndex: isDimmed ? 0 : 1 // если затемнено, «под» оверлеем
              }}
              onMouseEnter={() => handleImageHover(key)}
              onClick={(e) => {
                // Если зажаты S или D в режиме админа – предотвращаем переход
                if ((isSKeyPressed || isDKeyPressed) && isAdmin && adminViewEnabled) {
                  e.preventDefault();
                }
              }}
            />
          );
          
          // Элементы для админа
          if (isAdmin && adminViewEnabled) {
            // Инфопанель без лишнего фона
            elements.push(
              <div 
                key="info-panel"
                style={{
                  position: 'absolute',
                  top: 8,
                  left: !isFree ? 32 : 8,
                  zIndex: 10,
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px'
                }}
              >
                {/* Рейтинг (числовой) */}
                {(rating !== null && rating !== undefined) && (
                  <div
                    style={{
                      background: wasRatingChanged
                        ? 'rgba(255, 165, 0, 0.8)'
                        : 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                      padding: '2px 5px',
                      fontSize: '11px',
                      transition: 'background-color 0.3s',
                    }}
                    title="Нажмите 1-9 для изменения рейтинга"
                  >
                    {Math.round(rating)}★
                  </div>
                )}

                {/* Теги коллекции (если есть) */}
                {collection && (
                  <div style={{
                    background: '#4CAF50',
                    color: 'white',
                    padding: '2px 5px',
                    borderRadius: '3px',
                    fontSize: '11px',
                    maxWidth: '120px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                  title={collection}
                  >
                    {collection}
                  </div>
                )}
              </div>
            );
            
            // Бордюр, если это фото уже в выбранной коллекции
            if (isInSelectedCollection) {
              elements.push(
                <div
                  key="collection-indicator"
                  style={{
                    position: 'absolute',
                    top: 0, left: 0,
                    width: '100%', height: '100%',
                    border: '3px solid #4CAF50',
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                    pointerEvents: 'none',
                    zIndex: 2
                  }}
                />
              );
            }
            
            // Подсветка, если навели и зажата клавиша S
            if (isHovered && isSKeyPressed) {
              elements.push(
                <div
                  key="s-key-highlight"
                  style={{
                    position: 'absolute',
                    top: 0, left: 0,
                    width: '100%', height: '100%',
                    background: isInSelectedCollection 
                      ? 'rgba(255, 0, 0, 0.2)'    // удаление
                      : 'rgba(0, 255, 0, 0.2)', // добавление
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                    pointerEvents: 'none',
                    zIndex: 2
                  }}
                />
              );
            }
            
            // Подсветка, если навели и зажата клавиша D
            if (isHovered && isDKeyPressed) {
              elements.push(
                <div
                  key="d-key-highlight"
                  style={{
                    position: 'absolute',
                    top: 0, left: 0,
                    width: '100%', height: '100%',
                    background: isDimmed
                      ? 'rgba(0, 255, 0, 0.3)'   // восстанавливаем
                      : 'rgba(255, 0, 0, 0.3)', // удаляем
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                    pointerEvents: 'none',
                    zIndex: 2
                  }}
                />
              );
            }
            
            // "Добавлено/Удалено"
            if (wasProcessed && Date.now() - wasProcessed.timestamp < 1000) {
              const processedCollection = wasProcessed.collection || activeCollection;
              elements.push(
                <div
                  key="processing-feedback"
                  style={{
                    position: 'absolute',
                    top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)',
                    background: wasProcessed.action === 'add'
                      ? 'rgba(0, 128, 0, 0.7)'
                      : 'rgba(128, 0, 0, 0.7)',
                    color: 'white',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    zIndex: 3
                  }}
                >
                  {wasProcessed.action === 'add'
                    ? 'Добавлено в ' + processedCollection
                    : 'Удалено из ' + processedCollection
                  }
                </div>
              );
            }
          }
          
          return <>{elements}</>;
        },
      }}
    />
  );
};

export default Gallery;