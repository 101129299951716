import React, { useEffect, useState, useRef } from 'react';
import Gallery from '../components/Gallery.jsx';
import { useUserStore } from '../store';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const InfiniteScrollGallery = ({
  searchQuery,
  publicBackUrl,
  mediaType,
  isFree,
}) => {
  const [photos, setPhotos] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [authInitialized, setAuthInitialized] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  
  const maxPages = 15;

  // Refs for tracking state between renders
  const lastCombinedScoreRef = useRef(null);
  const lastIdRef = useRef(null);
  const isFetchingRef = useRef(false);
  const hasMoreRef = useRef(true);
  const searchIdRef = useRef(null);

  // Get user and admin info from the store
  const isAdmin = useUserStore(state => state.isAdmin);
  const email = useUserStore(state => state.email);
  // Get admin data from the store
  const adminViewEnabled = useUserStore(state => state.adminViewEnabled);
  const selectedCollection = useUserStore(state => state.selectedCollection);
  const customCollection = useUserStore(state => state.customCollection);
  
  // Создаем локальную функцию для получения активной коллекции
  const getActiveCollection = () => {
    return selectedCollection === 'custom' ? customCollection : selectedCollection;
  };

  // Initialize auth state
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, () => {
      setAuthInitialized(true);
    });
    return () => unsubscribe();
  }, []);

  const fetchPhotos = async () => {
    if (isFetchingRef.current || !hasMoreRef.current) return;

    // Check page limit
    if (pageCount > maxPages) {
      setHasMore(false);
      hasMoreRef.current = false;
      return;
    }

    isFetchingRef.current = true;
    setIsFetching(true);

    try {
      const formattedQuery = searchQuery.replace(/\-/g, ' ');

      const requestBody = {
        search_request: formattedQuery,
        last_combined_score: lastCombinedScoreRef.current,
        last_id: lastIdRef.current,
        media_type: mediaType || null,
        is_free: isFree !== null && isFree !== undefined ? isFree : null,
        user_id: email || null,
        page: pageCount,
        search_id: searchIdRef.current || null,
        is_admin: isAdmin || false,
      };

      const response = await fetch(`${publicBackUrl}/search`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }

      const data = await response.json();

      // Store search ID if not already set
      if (!searchIdRef.current && data.search_id) {
        searchIdRef.current = data.search_id;
      }

      if (data.items && data.items.length > 0) {
        const newPhotos = data.items.map((item) => ({
          src: item.url_small,
          width: item.url_small_width || 100,
          height: item.url_small_height || 100,
          alt: item.title,
          loading: 'lazy',
          key: item.slug,
          url:
            item.media_type === 'icon'
              ? `/icon/${item.slug}`
              : `/photo/${item.slug}`,
          isFree: item.is_free,
          mediaType: item.media_type,
          rating: item.rating,
          similarity: item.similarity,
          combinedScore: item.combined_score,
          collection: item.collection || null,
          is_active: item.is_active, // Добавляем поле is_active
        }));

        // Отладка - проверяем, есть ли у элементов is_active
        console.log("DEBUG: Первые 3 элемента из ответа:", 
          data.items.slice(0, 3).map(item => ({ 
            slug: item.slug, 
            is_active: item.is_active 
          }))
        );

        setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);

        // Update tracking refs
        lastCombinedScoreRef.current = data.last_combined_score;
        lastIdRef.current = data.last_id;

        setPageCount((prevPage) => prevPage + 1);

        if (pageCount >= maxPages) {
          setHasMore(false);
          hasMoreRef.current = false;
        }
      } else {
        setHasMore(false);
        hasMoreRef.current = false;
      }
    } catch (error) {
      console.error('Failed to fetch photos:', error);
      setHasMore(false);
      hasMoreRef.current = false;
    } finally {
      isFetchingRef.current = false;
      setIsFetching(false);
    }
  };

  // Fetch photos when search parameters change
  useEffect(() => {
    if (!authInitialized) return;

    // Reset state for new search
    setPhotos([]);
    setPageCount(1);
    setHasMore(true);
    hasMoreRef.current = true;
    lastCombinedScoreRef.current = null;
    lastIdRef.current = null;
    searchIdRef.current = null;

    fetchPhotos();
    isFetchingRef.current = false;
  }, [searchQuery, mediaType, isFree, authInitialized]);

  // Set up infinite scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (!hasMoreRef.current || isFetchingRef.current) return;

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Load more when we're 75% down the page
      if (scrollTop + windowHeight >= documentHeight * 0.75) {
        fetchPhotos();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [photos]);

  // Get the current active collection
  const activeCollection = getActiveCollection();

  return (
    <div>
      <div className="page-container">
        <h1 className='search-results-title'>
          {isAdmin && adminViewEnabled ? (
            <>Search results for "{searchQuery.replace(/\-/g, ' ')}"</>
          ) : (
            <>Search results for "{searchQuery.replace(/\-/g, ' ')}"</>
          )}
        </h1>
      </div>

      <Gallery photos={photos} isAdmin={isAdmin} publicBackUrl={publicBackUrl} />

      <div className="page-container" style={{ textAlign: 'center', padding: '20px 0' }}>
        {isFetching && <div>Loading more results...</div>}
        {!hasMore && photos.length > 0 && <div>No more results to display.</div>}
        {!hasMore && photos.length === 0 && <div>No results found for this search.</div>}
      </div>
    </div>
  );
};

export default InfiniteScrollGallery;