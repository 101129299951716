// AdminPanel.jsx
import React, { useEffect } from 'react';
import { useUserStore } from '../store';

const AdminPanel = () => {
  // Get all needed properties from the store
  const isAdmin = useUserStore(state => state.isAdmin);
  const adminViewEnabled = useUserStore(state => state.adminViewEnabled);
  const selectedCollection = useUserStore(state => state.selectedCollection);
  const customCollection = useUserStore(state => state.customCollection);

  // Check on first component load
  useEffect(() => {
    if (isAdmin && selectedCollection === undefined) {
      // Only set the collection if it's not defined
      useUserStore.setState({ selectedCollection: 'neural_backgrounds' });
      console.log("AdminPanel: Default collection set");
    }
  }, [isAdmin, selectedCollection]);

  // Handle collection change from dropdown
  const handleCollectionChange = (e) => {
    const value = e.target.value;
    
    // Direct state update for the selected collection
    useUserStore.setState({ selectedCollection: value });
    
    // Reset custom collection if a standard collection is selected
    if (value !== 'custom') {
      useUserStore.setState({ customCollection: '' });
    }
    
    console.log("Collection changed in AdminPanel:", value === 'custom' ? customCollection : value);
  };

  // Handle custom collection input
  const handleCustomCollectionChange = (e) => {
    const value = e.target.value;
    
    // Direct state update for the custom collection
    useUserStore.setState({ 
      customCollection: value,
      selectedCollection: 'custom' // Automatically select "custom" when typing
    });
    
    console.log("Custom collection changed:", value);
  };

  // Admin mode toggle handler
  const handleToggleAdminView = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Use the update function with previous state
    useUserStore.setState(state => ({
      adminViewEnabled: !state.adminViewEnabled
    }));
    
    console.log("Admin mode toggled to:", !adminViewEnabled);
  };

  // Do not render for regular users
  if (!isAdmin) {
    return null;
  }

  // Function to get the active collection
  const getActiveCollection = () => {
    return selectedCollection === 'custom' ? customCollection : selectedCollection;
  };

  // Get the current active collection
  const activeCollection = getActiveCollection();

  return (
    <div style={{
      position: 'fixed',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: '#111',
      color: 'white',
      padding: '12px 20px',
      zIndex: 9999,
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.3)',
      width: 'auto',
      maxWidth: '800px',
      fontFamily: 'Arial, sans-serif'
    }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {/* Collection selector */}
        <div>
          <select 
            style={{
              backgroundColor: '#333',
              color: 'white',
              padding: '8px 12px',
              borderRadius: '6px',
              border: '1px solid #555',
              outline: 'none'
            }}
            value={selectedCollection || 'neural_backgrounds'} // Ensure 'neural_backgrounds' by default
            onChange={handleCollectionChange}
          >
            <option value="neural_backgrounds">Neural Backgrounds</option>
            <option value="blue_illustrations">Blue Illustrations</option>
            <option value="test_collection">Test Collection</option>
            <option value="custom">Custom</option>
          </select>
        </div>
        
        {/* Custom collection input field */}
        {selectedCollection === 'custom' && (
          <input
            type="text"
            placeholder="Custom collection name"
            style={{
              backgroundColor: '#333',
              color: 'white',
              padding: '8px 12px',
              borderRadius: '6px',
              border: '1px solid #555',
              outline: 'none'
            }}
            value={customCollection}
            onChange={handleCustomCollectionChange}
          />
        )}
      </div>
      
      {/* Admin mode toggle */}
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px', whiteSpace: 'nowrap' }}>
        <span>Admin View</span>
        <button 
          onClick={handleToggleAdminView}
          style={{
            width: '44px',
            height: '22px',
            borderRadius: '11px',
            backgroundColor: adminViewEnabled ? '#4CAF50' : '#444',
            border: 'none',
            cursor: 'pointer',
            position: 'relative',
            flexShrink: 0
          }}
        >
          <span 
            style={{
              position: 'absolute',
              top: '3px',
              left: adminViewEnabled ? '24px' : '3px',
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              backgroundColor: 'white',
              transition: 'left 0.2s'
            }}
          />
        </button>
      </div>

      {/* Instructions in a separate info block */}
      <div style={{ 
        fontSize: '12px', 
        color: '#999', 
        display: 'flex', 
        alignItems: 'center',
        flexShrink: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
        <div style={{ 
          backgroundColor: '#333', 
          borderRadius: '4px', 
          padding: '4px 8px', 
          display: 'inline-block',
          maxWidth: '100%',
          overflow: 'hidden'
        }}>
          <span style={{ display: 'block', whiteSpace: 'nowrap' }}>
            <strong>S</strong> - {adminViewEnabled ? "add/remove from collection" : "manage collections"}
          </span>
          <span style={{ display: 'block', whiteSpace: 'nowrap' }}>
            <strong>D</strong> - darken/restore
          </span>
          <span style={{ display: 'block', whiteSpace: 'nowrap' }}>
            <strong>1-9</strong> - set rating
          </span>
        </div>
      </div>
      
      {/* Debug info removed */}
    </div>
  );
};

export default AdminPanel;
