import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import BaseLayout from '../layouts/BaseLayout.jsx';
import GalleryComponent from '../components/Gallery.jsx';
import { useUserStore } from '../store';

const CollectionPage = () => {
  const { collection } = useParams();
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const perPageOptions = [20, 50, 100, 200];
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  
  // Get admin status and related state from store
  const isAdmin = useUserStore(state => state.isAdmin);
  const adminViewEnabled = useUserStore(state => state.adminViewEnabled);

  // Create refs to store previous values
  const prevAdminViewEnabledRef = useRef(adminViewEnabled);
  const prevCollectionRef = useRef(collection);
  const prevPageRef = useRef(page);
  const prevPerPageRef = useRef(perPage);
  const prevIsAdminRef = useRef(isAdmin);

  // Access environment variables
  const BACK_URL = process.env.REACT_APP_BACK_URL || 'http://localhost:8000';

  const fetchCollectionPhotos = async () => {
    if (!collection) {
      setError('Collection name not provided');
      return;
    }

    try {
      setIsLoading(true);
      
      console.log('Fetching collection photos:', {
        collection,
        page,
        perPage,
        BACK_URL,
        isAdmin
      });

      // Send request to the updated endpoint
      const response = await fetch(`${BACK_URL}/collection-detail`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          collection,
          page,
          per_page: perPage,
          is_admin: isAdmin
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch collection photos');
      }

      const data = await response.json();
      console.log('Collection photos received:', data);

      // Set total pages and items count
      setTotalPages(data.total_pages || 0);
      setTotalItems(data.total_items || 0);

      // Transform received data to the format expected by Gallery component
      const processedPhotos = data.items.map(item => {
        const baseUrl = item.media_type === 'icon' ? '/icon' : '/photo';
        const photoUrl = `${baseUrl}/${item.slug}`;

        return {
          src: item.url_small,
          width: item.url_small_width || 300,
          height: item.url_small_height || 200,
          alt: item.title,
          loading: 'lazy',
          key: item.slug,
          url: photoUrl,
          isFree: item.is_free,
          collection: item.collection || '',
          rating: item.rating,
          mediaType: item.media_type || 'photo',
          assetId: item.asset_id,
          shortname: item.shortname,
          filename: item.filename,
          keywords: item.keywords,
          isChecked: item.is_checked,
          resultNumber: item.result_number
        };
      });

      setPhotos(processedPhotos);
    } catch (err) {
      console.error('Error during fetching:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // This useEffect runs once on mount and when dependencies change
  useEffect(() => {
    if (collection) {
      // Check if only adminViewEnabled has changed
      const onlyAdminViewChanged = 
        prevAdminViewEnabledRef.current !== adminViewEnabled &&
        prevCollectionRef.current === collection &&
        prevPageRef.current === page &&
        prevPerPageRef.current === perPage &&
        prevIsAdminRef.current === isAdmin;
      
      // Only fetch data if something other than adminViewEnabled changed
      if (!onlyAdminViewChanged) {
        fetchCollectionPhotos();
      }
    } else {
      setError('Collection name not provided');
    }
    
    // Update all refs after checking
    prevAdminViewEnabledRef.current = adminViewEnabled;
    prevCollectionRef.current = collection;
    prevPageRef.current = page;
    prevPerPageRef.current = perPage;
    prevIsAdminRef.current = isAdmin;
  }, [collection, page, perPage, isAdmin, adminViewEnabled]);

  // Page change handler
  const handlePageChange = (newPage) => {
    setPage(newPage);
    // Scroll to top on page change
    window.scrollTo(0, 0);
  };
  
  // Items per page change handler
  const handlePerPageChange = (newPerPage) => {
    setPerPage(parseInt(newPerPage));
    setPage(1); // Reset to first page when changing items per page
  };

  // Pagination component to be reused
  const PaginationControls = () => (
    <div className="pagination-container">
      {/* Items per page selector */}
      <div className="per-page-selector">
        <label htmlFor="per-page">Items per page:</label>
        <select 
          id="per-page" 
          value={perPage} 
          onChange={(e) => handlePerPageChange(e.target.value)}
          className="per-page-select"
        >
          {perPageOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      </div>
      
      {/* Pagination */}
      {totalPages > 1 && (
        <div className="pagination">
          <span className="current-page-info">
            Page {page} of {totalPages}
          </span>
          
          <div className="pagination-buttons">
            <button 
              className="pagination-button round"
              onClick={() => handlePageChange(page - 1)} 
              disabled={page === 1}
              aria-label="Previous page"
            >
              &larr;
            </button>
            
            <button 
              className="pagination-button round"
              onClick={() => handlePageChange(page + 1)} 
              disabled={page === totalPages}
              aria-label="Next page"
            >
              &rarr;
            </button>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <BaseLayout
      title={`Collection: ${collection}`}
      description={`View all assets in collection ${collection}`}
    >
      <div className="page-container-with-top-margin">
        <h1>Collection: {collection}</h1>
        
        {totalItems > 0 && (
          <p className="collection-info">
            Found items: {totalItems} | Page {page} of {totalPages}
          </p>
        )}
        
        {/* Top pagination controls */}
        {!isLoading && photos.length > 0 && <PaginationControls />}
        
        {isLoading && <div className="loading-indicator">Loading collection...</div>}
        {error && <div className="error-message">{error}</div>}
        
        {!isLoading && photos.length > 0 && (
          <GalleryComponent 
            photos={photos}
            isAdmin={isAdmin}
            adminViewEnabled={adminViewEnabled}
            publicBackUrl={BACK_URL}
          />
        )}
        
        {!isLoading && photos.length === 0 && !error && (
          <div className="empty-collection-message">No photos in this collection.</div>
        )}

        {/* Bottom pagination controls - identical to top */}
        {!isLoading && photos.length > 0 && <PaginationControls />}
        
        {/* Pagination styles */}
        <style jsx>{`
          .pagination-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 2rem 0;
            padding: 1rem 0;
            border-top: 1px solid #eee;
          }
          
          .per-page-selector {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          
          .per-page-select {
            padding: 8px 12px;
            border-radius: 4px;
            border: 1px solid #ddd;
            background-color: white;
            font-size: 14px;
          }
          
          .pagination {
            display: flex;
            align-items: center;
            gap: 20px;
          }
          
          .current-page-info {
            font-size: 14px;
            color: #666;
          }
          
          .pagination-buttons {
            display: flex;
            gap: 10px;
          }
          
          .pagination-button.round {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #000;
            color: white;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            cursor: pointer;
            transition: background-color 0.2s, transform 0.2s;
          }
          
          .pagination-button.round:hover {
            background-color: #333;
            transform: scale(1.05);
          }
          
          .pagination-button.round:disabled {
            background-color: #ccc;
            cursor: not-allowed;
            transform: none;
          }
        `}</style>
      </div>
    </BaseLayout>
  );
};

export default CollectionPage;