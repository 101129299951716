import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import useColorStore from '../store/colorStore';
import { useUserStore } from '../store';
import HomePage from './HomePage.jsx';
import PricingPage from './PricingPage.jsx';
import LoginPage from './LoginPage.jsx';
import PhotoDetailPage from './PhotoDetailPage.jsx';
import TermsPage from './TermsPage.jsx';
import PrivacyPage from './PrivacyPage.jsx';
import SearchPage from './SearchPage.jsx';
import IconDetailPage from './IconDetailPage.jsx';
import TemplateDetailPage from './TemplateDetailPage.jsx';
import CollectionPage from './CollectionPage.jsx';
import RatingToolPage from './RatingToolPage.jsx';
import MakeMeCoverPage from './MakeMeCoverPage.jsx';
import AdminPanel from '../components/AdminPanel.jsx';
import '../styles/font.css';
import posthog from 'posthog-js';

// Initialize analytics
posthog.init('phc_czZCgIeU9rdqy0RTNvSc0OCrKDD1JzcdyEsc9qg2KNA', {
  api_host: 'https://eu.i.posthog.com',
  person_profiles: 'always'
});

function App() {
  const { background, primary, backgroundShaded, primaryShaded, secondary } = useColorStore(state => state.pageColors);
  const initializeUser = useUserStore(state => state.initializeUser);
  const isAdmin = useUserStore(state => state.isAdmin);

  // Initialize user authentication
  useEffect(() => {
    const unsubscribe = initializeUser();
    return () => unsubscribe();
  }, [initializeUser]);

  // Set global CSS variables for colors
  useEffect(() => {
    document.documentElement.style.setProperty('--page-background', background);
    document.documentElement.style.setProperty('--page-primary', primary);
    document.documentElement.style.setProperty('--page-background-shaded', backgroundShaded);
    document.documentElement.style.setProperty('--page-primary-shaded', primaryShaded);
    document.documentElement.style.setProperty('--page-secondary', secondary);
  }, [background, primary, backgroundShaded, primaryShaded, secondary]);

  // Принудительно инициализируем правильные значения администратора только при первой загрузке приложения
  useEffect(() => {
    // Используем переменную для отслеживания первичной инициализации
    const hasInitialized = useUserStore.getState().__hasInitialized;
    
    // Проверяем, является ли пользователь администратором и не выполнялась ли инициализация ранее
    if (isAdmin && !hasInitialized) {
      // Через малую задержку для гарантии обновления состояния
      const timer = setTimeout(() => {
        const currentState = useUserStore.getState();
        
        // Устанавливаем значения только при первой загрузке или если они не определены
        useUserStore.setState({ 
          ...(currentState.selectedCollection ? {} : { selectedCollection: 'test' }),
          ...(currentState.adminViewEnabled === undefined ? { adminViewEnabled: true } : {}),
          __hasInitialized: true // Отмечаем, что инициализация произошла
        });
        console.log("App: Первоначальная инициализация состояния администратора");
      }, 200);
      
      return () => clearTimeout(timer);
    }
  }, [isAdmin]);

  return (
    <Router>
      {/* 
        The AdminPanel is placed outside the Routes but inside the Router
        so it's available throughout the application. It self-checks if the 
        user is an admin before rendering anything.
      */}
      <AdminPanel />
      
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/prices" element={<PricingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/photo/:slug" element={<PhotoDetailPage />} />
        <Route path="/icon/:slug" element={<IconDetailPage />} />
        <Route path="/template/:slug" element={<TemplateDetailPage />} />
        <Route path="/search/:query" element={<SearchPage />} />
        <Route path="/collection/:collection" element={<CollectionPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/rating-tool" element={<RatingToolPage />} />
        <Route path="/makemecover" element={<MakeMeCoverPage />} />
      </Routes>
    </Router>
  );
}

export default App;