import React, { useEffect, useState } from 'react';
import GalleryComponent from '../../components/Gallery.jsx';
import { useUserStore } from '../../store';

const SimilarPhotos = ({ slug }) => {
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Get admin status from store
  const isAdmin = useUserStore(state => state.isAdmin);

  const fetchSimilarPhotos = async () => {
    try {
      setIsLoading(true);

      // console.log('Sending request to server with slug:', slug);

      const response = await fetch(`${process.env.REACT_APP_BACK_URL}/similar-assets`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          slug,
          is_admin: isAdmin // Pass admin status directly
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch similar photos');
      }

      const data = await response.json();
      // console.log('Similar photos received:', data);

      const updatedPhotos = await Promise.all(
        data.items.map(async (item) => {
          const baseUrl = item.media_type === 'icon' ? '/icon' : '/photo';
          const photoUrl = `${baseUrl}/${item.slug}`;

          // Create initial photo object
          const photoObject = {
            src: item.url_small,
            alt: item.title,
            loading: 'lazy',
            key: item.slug,
            url: photoUrl,
            isFree: item.is_free,
            collection: item.collection || '',
            rating: item.rating || null,
            mediaType: item.media_type || 'photo'
          };
          
          // Add is_active property if available (for admin users) - KEEP as is_active
          if (isAdmin && 'is_active' in item) {
            photoObject.is_active = item.is_active;  // Keep original snake_case
            // Debug statement to verify the property is present
            // console.log(`Adding is_active=${item.is_active} for item slug=${item.slug}`);
          }

          if (!item.url_small_width || !item.url_small_height) {
            try {
              const img = new Image();
              img.src = item.url_small;

              await new Promise((resolve, reject) => {
                img.onload = () => resolve();
                img.onerror = () => reject(new Error(`Image load error for: ${item.url_small}`));
              });

              // console.log(`Image loaded: ${item.url_small}, dimensions: ${img.naturalWidth}x${img.naturalHeight}`);

              photoObject.width = img.naturalWidth;
              photoObject.height = img.naturalHeight;
              
              return photoObject;
            } catch (error) {
              console.error('Error loading image:', error.message);
              return null; // Skip images that failed to load
            }
          } else {
            // Use existing dimensions
            photoObject.width = item.url_small_width;
            photoObject.height = item.url_small_height;
            
            // console.log(`Using existing image dimensions for: ${item.url_small}`);
            return photoObject;
          }
        })
      );

      // Filter out null values (if images failed to load)
      setPhotos(updatedPhotos.filter((photo) => photo !== null));
    } catch (err) {
      console.error('Error during fetching:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (slug) {
      // console.log('Fetching similar photos for slug:', slug);
      fetchSimilarPhotos();
    } else {
      console.log('No slug provided.');
    }
  }, [slug, isAdmin]); // Add isAdmin to dependencies

  return (
    <div>
      <h2>Similar Photos</h2>
      {isLoading && <div>Loading similar photos...</div>}
      {error && <div>{error}</div>}
      {!isLoading && photos.length > 0 && (
        <GalleryComponent 
          photos={photos}
          isAdmin={isAdmin}
          publicBackUrl={process.env.REACT_APP_BACK_URL}
        />
      )}
      {!isLoading && photos.length === 0 && !error && (
        <div>No similar photos found.</div>
      )}
    </div>
  );
};

export default SimilarPhotos;