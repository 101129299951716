// src/store/store_asset_detail.js
import { create } from 'zustand';

// We grab the base URL from the environment; if it is undefined, we can default to your existing URL:
const BASE_URL = process.env.REACT_APP_BACK_URL || 'https://awesomeheap-back-sx8i6.ondigitalocean.app';

const useAssetStore = create((set) => ({
  asset: null,
  loading: false,
  error: null,

  fetchAssetBySlug: async (slug) => {
    console.log('Fetching asset with slug:', slug);
    set({ loading: true, error: null, asset: null });

    try {
      const response = await fetch(`${BASE_URL}/asset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ slug }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Asset fetched:', data);
        set({ asset: data, loading: false });
      } else {
        const errorData = await response.json();
        console.error('Error fetching asset:', errorData);
        set({ error: errorData.detail || 'Asset not found', loading: false });
      }
    } catch (err) {
      console.error('Fetch error:', err);
      set({ error: err.message || 'Unknown error', loading: false });
    }
  },

  clearAsset: () => {
    console.log('Clearing asset');
    set({ asset: null, loading: false, error: null });
  },
}));

export { useAssetStore };
